import React from 'react'

type buttonVarient =  'primary'|'outlined'|'accent'


type Props = {
    text?:string,
    icon:any,
    onClick?:(e:React.MouseEvent<HTMLButtonElement, MouseEvent>|any)=>void,
    varient?:buttonVarient
}

export function IconButton({icon,text,onClick=()=>{},varient='primary'}:Props) {

    switch(varient){
        case 'primary':{
            return (
                <button onClick={onClick} type="button" className="text-white bg-primarry-400 hover:bg-primarry-400/80 focus:ring-4 focus:outline-none focus:ring-pribg-primarry-400/50 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center dark:hover:bg-primarry-400/80 dark:focus:ring-primarry-400/40 ">
                    {icon}
                    {text}
                </button>
        
            )
        }
        case 'accent':
            return (
                <button onClick={onClick} type="button" className="text-white bg-accent-400 hover:bg-accent-400/80 focus:ring-4 focus:outline-none focus:ring-pribg-accent-400/50 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center dark:hover:bg-accent-400/80 dark:focus:ring-accent-400/40 ">
                    {icon}
                    {text}
                </button>
        
            )
        case 'outlined':
            return (
                <button onClick={onClick} type="button" className="text-white bg-accent-400 hover:bg-accent-400/80 focus:ring-4 focus:outline-none focus:ring-pribg-accent-400/50 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center dark:hover:bg-accent-400/80 dark:focus:ring-accent-400/40 ">
                    {icon}
                    {text}
                </button>
        
            )
    }
    
}
