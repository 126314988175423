import React, { useEffect, useRef, useState } from 'react'

type Props = {
    interaction: any,
    content: any,
    top?: number,
    right?: number,
    left?: number,
    bottom?: number
}

export function SimpleMenu({
    interaction,
    content,
    top = 35,
    right,
    left,
    bottom
}: Props) {

    const [open, setOpen] = useState(false)
    const ref = useRef<HTMLDivElement>(null);
    const refButton = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false)
        }
    };

    useEffect(
        () => {
            document.addEventListener('click', handleClickOutside);

            // Cleanup function to remove event listener on unmount
            return () => document.removeEventListener('click', handleClickOutside);
        }, [ref.current,handleClickOutside]
    )

    return (
        <div ref={ref} className='relative flex justify-center'>
            <div ref={refButton} onClick={()=>setOpen(true)}>{interaction}</div>
            {open&&<div  style={{ top, right, left, bottom }} className=' absolute border-2 border-primarry-300 rounded-md bg-white'  >
                {content}
            </div>}
        </div>
    )
}
