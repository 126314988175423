import React, { useEffect, useState } from 'react'
import { headerDataType } from '../App';

export function ToolTip({ itemData }:{itemData:headerDataType|null}) {

    const [toolTipData, setToolTipData] = useState<headerDataType|null>(null)


    useEffect(
        () => {
            const handleMouseMove = (event:MouseEvent) => {
                // console.log(toolTipData)
                toolTipData!.left = event.clientX
                toolTipData!.top = event.clientY
                setToolTipData({ ...toolTipData! })
            };
            setToolTipData(itemData)
            if (toolTipData) {
                window.addEventListener('mousemove', handleMouseMove)
                return () => window.removeEventListener('mousemove', handleMouseMove)
            }
        }, [itemData, toolTipData?.left,toolTipData]
    )




    if (itemData) {

        return (
            <div style={{
                left: toolTipData?.left,
                top: toolTipData?.top,
                zIndex:30,
                lineHeight:0.6
            }} className='fixed cursor-move'>{itemData.text}</div>
        )
    }
    return null
}
