import React, { RefObject } from 'react';
import { TextMode } from '../../pages/pdfEditor/entities/index';

interface Props {
  inputRef: RefObject<HTMLInputElement>;
  text?: string;
  mode: string;
  width: number;
  size?: number;
  height: number;
  lineHeight?: number;
  fontFamily?: string;
  positionTop: number;
  positionLeft: number;
  toggleEditMode: () => void;
  handleMouseDown: DragEventListener<HTMLDivElement>;
  handleMouseUp: DragEventListener<HTMLDivElement>;
  handleMouseMove: DragEventListener<HTMLDivElement>;
  handleMouseOut: DragEventListener<HTMLDivElement>;
  onChangeText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean,
  isElementDragging: React.MutableRefObject<boolean>,
  isStyled?: boolean
}

export const Text: React.FC<Props> = ({
  text,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
  positionTop,
  positionLeft,
  onChangeText,
  toggleEditMode,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  lineHeight,
  selected,
  isElementDragging,
  isStyled = true
}) => {
  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut}
      onDoubleClick={toggleEditMode}
      style={{
        // width,
        // border: 1,
        // height,
        fontFamily,
        fontSize: size,
        // lineHeight:0,
        lineHeight,
        cursor: mode === TextMode.COMMAND ? 'move' : 'default',
        top: positionTop,
        left: positionLeft,
        // borderColor: 'gray',
        // borderStyle: 'solid',
        wordWrap: 'break-word',
        padding: 0,
        position: 'absolute',
      }}
    // className={`${selected ? 'bg-accent-300/40' : 'bg-transparent'}`}
    >
        {isStyled ?
          <span
            className={` border border-black ${selected ? 'bg-accent-300/40' : 'bg-transparent'}`}
            style={{ fontSize: size, fontFamily }}
          >
            {text}
          </span>
          :
          <span
            style={{ fontSize: size, fontFamily }}
          >
            {text}
          </span>
        }
    </div>
  );
};
