import { IconFileTypePdf, IconFileTypeXls, IconPhoto } from '@tabler/icons-react';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import './App.css';
import { FileInput } from './components/dragInputFile';
import { DraggableElement } from './components/draggables';
import { ToolTip } from './components/tootTip';
import ImageEditor from './pages/imageEditor/page';
import { AttachmentTypes } from './pages/pdfEditor/entities';
import { Pdf, PdfEditor } from './pages/pdfEditor/pdfEditor';
import { readAsArrayBuffer } from './pages/pdfEditor/utils/asyncReader';
import { ggID } from './pages/pdfEditor/utils/helpers';
import { getAsset } from './pages/pdfEditor/utils/prepareAssets';


export type headerStateType = 'selected' | 'unselected'


export interface headerDataType {
  id: string,
  label: string,
  text: string,
  state: headerStateType,
  dropped: boolean,
  top: number,
  left: number,
}

export interface rowDataType {
  id: string | number,
  data: any
}

type documentType = 'pdf' | 'image'

export type imageResolutionType = {
  width: number,
  height: number
}

export type DefaultValues = {
  fontSize: number
}

export type selectedElementStates = {
  id: string | null
}

function App() {
  const [headerData, setHeaderData] = useState<headerDataType[]>([
    // {
    //   "id": 0,
    //   "text": "name",
    //   "state": "unselected",
    //   "dropped": false,
    //   "top": 0,
    //   "left": 0,
    //   "label": "name"
    // },
    // {
    //   "id": 1,
    //   "text": "address",
    //   "state": "unselected",
    //   "dropped": false,
    //   "top": 0,
    //   "left": 0,
    //   "label": "address"
    // },
    // {
    //   "id": 2,
    //   "text": "age",
    //   "state": "unselected",
    //   "dropped": false,
    //   "top": 0,
    //   "left": 0,
    //   "label": "age"
    // },
    // {
    //   "id": 3,
    //   "text": "place",
    //   "state": "unselected",
    //   "dropped": false,
    //   "top": 0,
    //   "left": 0,
    //   "label": "place"
    // }
  ]);
  const [excelFileName, setExcelFileName] = useState('')
  const [docFileName, setdocFileName] = useState('')
  const [toolTipData, setToolTipData] = useState<headerDataType | null>(null)
  const [imageResolution, setImageResolution] = useState<imageResolutionType>();
  const [row, setRow] = useState<rowDataType[]>([])
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [documentType, setDocumentType] = useState<documentType>()
  const [loadingDocs, setLoadingDocs] = useState<Boolean>(false)
  const [pdf, setPdf] = useState<Pdf>()
  const editorContentRef: RefObject<HTMLDivElement> = useRef(null);
  const editorContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const screenScale = useRef<number>(1)
  const isElementDragging = useRef<boolean>(false);
  // const selectedElementOption = useRef<selectedElementStates>({})
  const [allPageAttachments, setAllPageAttachments] = useState<Attachments[]>([])
  const [selectedAttachement, setSelectedAttachement] = useState<Attachment>()
  const [pageIndex, setPageIndex] = useState(-1);
  const [defaultValues, setDefaultValue] = useState<DefaultValues>({
    fontSize: 16
  })
  //on excel upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return; // Handle empty file selection gracefully
    }
    setExcelFileName(file.name)

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      try {
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers1: headerDataType[] = jsonData[0].map((item: any, index: any) => ({
          id: index,
          text: item,
          state: 'unselected',
          dropped: false,
          top: 0,
          left: 0,
          label: `${item}-${index}`
        } as headerDataType))
        const headers = headers1.filter(
          item => item
        )
        let row: rowDataType[] = []
        for (let i = 1; i < jsonData.length; i++) {

          let voidData = false

          const data: any = {}
          headers.map(
            ((item, index: number) => {
              console.log(jsonData[i][index])
              if (!jsonData[i][index]) {
                voidData = true
              }
            })
          )
          if (voidData) {
            break
          }
          headers.map(
            ((item, index: number) => {
              data[item.label] = jsonData[i][index]
            })
          )
          row.push({
            id: i,
            data
          })
        }

        setRow(row)
        console.log(headers)
        console.log(row)
        setHeaderData(headers);

      } catch (error) {
        console.error('Error parsing Excel data:', error);
        // Handle parsing errors gracefully (e.g., display error message)
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files![0];
    const blobURL = URL.createObjectURL(file); // Create temporary URL
    setSelectedImage(blobURL); // Update state with temporary URL
    setLoadingDocs(true)
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {

      //taking image width and height
      const img: HTMLImageElement = new Image();
      img.onload = () => {
        setImageResolution({ width: img.width, height: img.height })
      }
      img.src = e.target?.result as string
      // setSelectedImage(e.target?.result as string);
      setAllPageAttachments([[]])
      setPageIndex(0)
      setLoadingDocs(false)
      // URL.revokeObjectURL(blobURL);

    };
    reader.readAsDataURL(file);

  };

  async function handlePdfChange(event: React.ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }) {

    const file = event.target?.files?.[0]
    if (file) {
      const pdfJsLib = await getAsset('pdfjsLib')
      // Safari possibly get webkitblobresource error 1 when using origin file blob
      const blob = new Blob([file]);
      const url = window.URL.createObjectURL(blob);
      //reading as pdf
      const pdf = await pdfJsLib.getDocument(url).promise;
      //this is pdf details
      const result = {
        file,
        name: file.name,
        pages: Array(pdf.numPages)
          .fill(0)
          .map((_, index) => pdf.getPage(index + 1)),
      } as Pdf;

      const outerArray = new Array(result.pages.length);

      // Fill the outer array with empty inner arrays
      for (let i = 0; i < outerArray.length; i++) {
        outerArray[i] = [];
      }
      // console.log(outerArray)

      setAllPageAttachments(outerArray)


      setPdf(result)
    }
  }


  //onClick inside image
  const handleClick = (event: MouseEvent) => {
    const clickedDiv = editorContentRef.current; // Get the clicked element (div)

    // Get the bounding rectangle of the clicked div relative to the viewport
    const rect = clickedDiv!.getBoundingClientRect();

    // Calculate the click position relative to the div
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    const selectedIndex = headerData.findIndex((item) => item.state === 'selected')
    // console.log(selectedIndex)

    if (selectedIndex >= 0) {
      switch (documentType) {
        case 'image': {
          const clickX = (event.clientX - rect.left) * (1 / screenScale.current);
          const clickY = (event.clientY - rect.top) * (1 / screenScale.current);
          const dataUnselect = headerData.map(item => ({ ...item, state: 'unselected' } as headerDataType))
          dataUnselect[selectedIndex] = { ...headerData[selectedIndex], state: 'unselected', 
          // dropped: true,
           top: clickY, left: clickX }
          setHeaderData(dataUnselect)

          const attachment: TextAttachment = {
            id: ggID(),
            type: AttachmentTypes.TEXT,
            x: clickX,
            y: clickY,
            width: 120,
            height: 25,
            size: defaultValues.fontSize,
            lineHeight: 0,
            fontFamily: 'Times-Roman',
            text: dataUnselect[selectedIndex].text,
            label: dataUnselect[selectedIndex].label,
            selected: true
          };
          // console.log(pageIndex)
          const newAllPageAttachmentsAdd = allPageAttachments.map(
            (attachments, index) => {
              if (pageIndex === index) {
                attachments.map(
                  attachment => attachment.selected = false
                )
                return [...attachments, attachment]
              } else {
                attachments.map(
                  attachment => attachment.selected = false
                )
                return attachments
              }
            }
          );
          // console.log(newAllPageAttachmentsAdd)
          setAllPageAttachments(newAllPageAttachmentsAdd)
          // setToolTipData(null)
          break;
        }

        case 'pdf': {
          const dataUnselect = headerData.map(item => ({ ...item, state: 'unselected' } as headerDataType))
          dataUnselect[selectedIndex] = { ...headerData[selectedIndex], state: 'unselected', 
          // dropped: true, 
          top: clickY, left: clickX }
          setHeaderData(dataUnselect)

          const attachment: TextAttachment = {
            id: ggID(),
            type: AttachmentTypes.TEXT,
            x: clickX,
            y: clickY,
            width: 120,
            height: 25,
            size: 16,
            lineHeight: 1.4,
            fontFamily: 'Times-Roman',
            text: dataUnselect[selectedIndex].text,
            label: dataUnselect[selectedIndex].label,
            selected: true
          };

          setToolTipData(null)

          if (pageIndex < 0) return;

          // console.log(allPageAttachments)

          const newAllPageAttachmentsAdd = allPageAttachments.map(
            (attachments, index) => {
              if (pageIndex === index) {
                attachments.map(
                  attachment => attachment.selected = false
                )
                return [...attachments, attachment]
              } else {
                attachments.map(
                  attachment => attachment.selected = false
                )
                return attachments
              }
            }
          );
          // console.log(newAllPageAttachmentsAdd)
          setAllPageAttachments(newAllPageAttachmentsAdd)
          break;
        }

        default:
          break;
      }

    }

    setToolTipData(null)

    // console.log(`Clicked at position within div: X: ${clickX}, Y: ${clickY}`);
  }

  const handleClickInside = (event: MouseEvent) => {
    // event.preventDefault()
    const rect = editorContainerRef.current?.getBoundingClientRect();

    // calculate positions of div
    if (rect) {
      const XL = rect.left;
      const XR = rect.right;
      const YT = rect.top;
      const YB = rect.bottom;

      //calculate clickPosition
      const clickX = event.clientX
      const clickY = event.clientY

      // Check if click coordinates fall within the div's bounding rectangle
      if (clickX >= XL && clickX <= XR && clickY >= YT && clickY <= YB) {
        handleClick(event)
        // console.log('Clicked inside!');
      }
    }


  };

  const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];

    const file = event.target.files?.[0];
    if (!file) {
      return; // Handle empty file selection gracefully
    }
    const extension = file.name.split('.').pop()?.toLowerCase();
    console.log(extension)
    if (!allowedExtensions.includes(extension!)) {

      alert('unsupported file')

      return;
    }
    switch (extension) {
      case 'pdf':
        setDocumentType('pdf')
        handlePdfChange(event)
        break;

      default:
        setDocumentType('image')
        handleImageUpload(event)
        break;
    }
  }

  const handleRightClick = (event: MouseEvent) => {
    event.preventDefault()
    if (toolTipData) {
      const dataUnselect = headerData.map(item => ({ ...item, state: 'unselected' } as headerDataType))

      setToolTipData(null)
      setHeaderData(dataUnselect)
    }
  }


  useEffect(() => {
    // Add event listener on document mount
    document.addEventListener('click', handleClickInside);
    document.addEventListener('contextmenu', handleRightClick);

    // Clean up on component unmount
    return () => {
      document.removeEventListener('click', handleClickInside);
      document.removeEventListener('contextmenu', handleRightClick);
    }
  }, [toolTipData])

  // console.log(imageResolution)


  return (
    <div className="App">
      <ToolTip itemData={toolTipData} />

      <div className=" fixed inset-0 flex flex-col items-center p-2 overflow-auto">

        <div className=' grow w-full h-full flex gap-2  overflow-hidden '>
          {/* HEADER SECTION */}
          <div className=" min-w-[300px] max-w-[300px] flex">
            {headerData.length > 0 ? (
              <div className=' border rounded-md shadow-sm flex flex-col grow overflow-hidden'>
                <div className=' w-full bg-primarry-500 text-white font-bold  flex items-center justify-center py-1'>
                  Table Heads
                </div>
                <div className=' flex flex-col pt-2 gap-2 px-2'>
                  {headerData.map((item, cellIndex) => {
                    if (!item.dropped) {
                      return (
                        <DraggableElement
                          text={item.text}
                          id={item.id}
                          // className=" hover:bg-gray-100 px-2 py-1 w-full cursor-pointer"
                          key={cellIndex}
                          onSelect={(e) => {
                            const index = headerData.findIndex((value) => value.id === item.id);
                            const dataUnselect = headerData.map(item => ({ ...item, state: 'unselected' } as headerDataType))
                            dataUnselect[index] = { ...item, state: 'selected' }
                            dataUnselect[index].left = e.clientX
                            dataUnselect[index].top = e.clientY
                            setToolTipData(dataUnselect[index])
                            setHeaderData(dataUnselect)
                          }}
                          state={item.state}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            ) :
              <div className=' grow flex justify-center items-center'>
                <FileInput
                  accept='.xlsx'
                  onFileChange={handleFileUpload}
                  className={(isDragOver) => ' h-full w-full '}
                  icon={<IconFileTypeXls className=' text-gray-400' size={35} />}
                  nonDragText='Select your excel file by clicking here or drag and drop '
                />
              </div>
            }
          </div>
          {/* EDITOR SECTION */}
          <div className=' flex grow flex-col overflow-hidden'>
            {
              loadingDocs === false
                ?
                documentType
                  ?
                  <div className='grow overflow-hidden flex'>

                    {
                      documentType === 'image'
                        ?
                        <ImageEditor
                          allPageAttachments={allPageAttachments}
                          pageIndex={pageIndex}
                          setAllPageAttachments={setAllPageAttachments}
                          key={1}
                          editorRef={editorContainerRef}
                          editorContentRef={editorContentRef}
                          headerData={headerData}
                          selectedImage={selectedImage}
                          imageResolution={imageResolution}
                          row={row}
                          screenScaleRef={screenScale}
                          isElementDragging={isElementDragging}
                          setDefaultValue={setDefaultValue}
                          defaultValues={defaultValues}
                          setSelectedAttachment={setSelectedAttachement}
                          selectedAttachement={selectedAttachement}
                        />
                        :
                        <PdfEditor
                          pdf={pdf}
                          allPageAttachments={allPageAttachments}
                          setAllPageAttachments={setAllPageAttachments}
                          pageIndex={pageIndex}
                          setPageIndex={setPageIndex}
                          editorContentRef={editorContentRef}
                          editorRef={editorContainerRef}
                          row={row}
                          setDefaultValue={setDefaultValue}
                          defaultValues={defaultValues}
                          setSelectedAttachment={setSelectedAttachement}
                          selectedAttachement={selectedAttachement}
                          headerData={headerData}
                        />
                    }

                  </div>
                  : <FileInput


                    onFileChange={handleDocumentUpload}
                    className={(isDragOver) => ' h-full w-full'}
                    nonDragText='Drop Your Image or Pdf document'
                    icon={
                      <div className=' flex'>
                        <IconPhoto className=' text-gray-400' size={50} />
                        <div className='px-2 flex items-end font-bold text-gray-400'>or</div>
                        <IconFileTypePdf className=' text-gray-400' size={50} />
                      </div>}
                  />
                : <div className=' grow bg-white flex justify-center items-center'>Loading...</div>
            }
            {
              documentType === 'pdf' &&

                (pdf?.pages?.length && pdf?.pages.length)
                ?
                <div className=' m-auto flex border rounded-md w-min'>
                  <div className='p-2' onClick={
                    () => {
                      if (pageIndex > 0) {
                        setPageIndex(pageIndex - 1)
                      }
                    }
                  } >{'<'}</div>
                  <div className=' border-x p-2 whitespace-nowrap'>{pageIndex + 1} of {pdf.pages.length}</div>
                  <div className=' p-2' onClick={
                    () => {
                      if (pdf.pages.length > pageIndex + 1) {
                        setPageIndex(pageIndex + 1)
                      }
                    }
                  } >{'>'}</div>
                </div>
                :
                null

            }
          </div>
        </div>
        {/* <button onClick={downloadDocument}>Download Image Container</button> */}
      </div>
    </div>
  );
}

export default App
