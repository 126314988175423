import React, { useState, useRef } from 'react';
import { EditorCommonProps } from './editorMeterial';


type Props ={
  onFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement> | any) => void,
  className?: (isDrageOver: Boolean) => string,
  nonDragText?: string,
  dragoverText?: string,
  icon?: any,
  accept?: string
}

const FileInput = ({ onFileChange,
  className = () => '',
  nonDragText = 'Drag and drop your file here or click to select a file',
  dragoverText = 'Drop your file here',
  icon,
  accept,
  
}: Props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    if (event.dataTransfer.files.length > 0) {
      onFileChange(event);
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileChange(event)
  };

  const handleClickFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`border-dashed rounded-md border-2 cursor-pointer flex flex-col items-center justify-center text-center p-2 ${isDragOver ? ' border-primarry-500 ' : ''} ${className(isDragOver)}`}
      onClick={handleClickFileInput}
    >
      <input
        accept={accept}
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      {icon}
      {isDragOver ? dragoverText : nonDragText}
    </div>
  );
};

export { FileInput };