import html2canvas from "html2canvas";
import { imageResolutionType, rowDataType } from "../../../App";
import { toBlob, toPng } from "html-to-image";


export async function downloadImageByPage(
    row:rowDataType,
    imageResolution:imageResolutionType,
    bgImage:string,
    attachements:Attachments
): Promise<Blob> {

    const rowData = row
    const imageContainer = document.createElement('div')
    imageContainer.style.position = 'relative';
    imageContainer.style.background = 'black'
    const imageDiv = document.createElement('img')
    imageContainer.style.width = `${imageResolution.width}px`
    // imageContainer.style.width = `${imageResolution.width}px`
    imageContainer.style.height = `${imageResolution.height}px`
    // imageContainer.style.height = `${imageResolution.height}px`
    imageDiv.style.height = `${imageResolution.height}px`
    imageDiv.style.width = `${imageResolution.width}px`
    // imageDiv.style.height = `${imageResolution.height}px`
    // imageDiv.style.width = `${imageResolution.width}px`
    imageDiv.src = bgImage as string

    attachements.forEach(
        (item) => {
            const text = document.createElement('div')
            const item1 = item as TextAttachment
            text.innerHTML = rowData.data[item1.label!]
            text.style.whiteSpace = 'nowrap'
            text.style.position = 'absolute'
            // text.style.lineHeight = `${0.2}`
            text.style.top = `${(item.y)}px`
            text.style.left = `${item.x}px`
            // text.style.fontSize = `${16 * heightRatio}px`
            // text.style.top = `${(item.top * heightRatio) - ((16 * heightRatio) / 2)}px`
            // text.style.left = `${item.left * widthtRatio}px`
            text.style.fontSize = `${item1.size}px`
            text.style.fontFamily = item1.fontFamily!
            imageContainer.appendChild(text)
        }
    )

    const outerDiv = document.createElement('div');

    outerDiv.style.position = 'absolute'
    outerDiv.style.left = `${window.innerWidth + 50}px`

    const canvas = document.createElement('canvas');
    canvas.width = imageResolution.width; // Set desired width
    canvas.height = imageResolution.height; // Set desired height



    imageContainer.appendChild(imageDiv)
    outerDiv.appendChild(imageContainer)
    document.body.appendChild(outerDiv)
    // const imageData = await html2canvas(imageContainer, { allowTaint: true, useCORS: true, canvas, scale: 1 });
    
    try {
        // return  await new Promise(
        //     (resolve, reject) => {
        //         imageData.toBlob(async (imageBlob: any) => {
        //             // Add the image file to the ZIP archive (customize filename)
        //             console.log(imageBlob)
        //             resolve(imageBlob)
        //         }, 'image/png')
        //     }
        // )
        
        return toBlob(
            imageContainer
        ).then(function (dataUrl) {
            console.log(dataUrl)
            imageContainer.remove()
            return dataUrl
          }) as Promise<Blob>


    } catch (error) {
        throw new Error('error downloading image')
    }

}