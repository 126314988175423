import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { headerDataType, imageResolutionType, rowDataType } from '../../App'
import { IconButton } from '../../components/button'
import { Input } from '../../components/inputs'
import { downloadImageByPage } from '../pdfEditor/utils/downloads'


type Props = {
    show: Boolean,
    close: () => void,
    selectedImage: string | null,
    imageResolution: imageResolutionType | undefined,
    allPageAttachments: Attachments[],
    pageIndex: number,
    isElementDragging: React.MutableRefObject<boolean>,
    headerData: headerDataType[],
    row: rowDataType[],
}

export default function PreviewPage({
    show = false,
    close,
    selectedImage,
    imageResolution,
    allPageAttachments,
    pageIndex,
    isElementDragging,
    headerData,
    row
}: Props) {

    const [isLoading, setIsLoading] = useState<boolean>()
    const [ratio, setRatio] = useState(0);
    const [imagesrc, setImageSrc] = useState<string>()
    const [documentNo,setDocumentNo] = useState<number>(1)
    
    const [newImage, setImage] = useState<{ height: number, width: number }>({
        width: 0,
        height: 0
    })

    useEffect(
        () => {
            let blobUrl: string

            async function handleInit() {

                setImageSrc(undefined)
                setIsLoading(true)

                const imageBlob = await downloadImageByPage(row[documentNo-1], imageResolution!, selectedImage!, allPageAttachments[0])
                blobUrl = URL.createObjectURL(imageBlob)
                setImageSrc(blobUrl)
                setIsLoading(false)

                // const fileReader = new FileReader();
                // fileReader.readAsDataURL(await Promise.resolve(imageBlob))
                // fileReader.onloadend = () => {
                //     if (fileReader.result) {
                //         setImageSrc(fileReader.result as string)
                //     }
                // };
            }
            if (show && selectedImage) {
                handleInit()
            }

            return () => {
                if (blobUrl) {
                    URL.revokeObjectURL(blobUrl)
                }
            }

        }, [show,documentNo]
    )

    if (show === false) {
        return null
    }

    let attachements: TextAttachment[] = []

    if (show) {

        attachements = allPageAttachments[pageIndex].map(
            (attachement) => {
                const edited: TextAttachment = { ...attachement }

                edited.x = edited.x * ratio
                edited.y = edited.y * ratio
                edited.size = edited.size! * ratio
                edited.text = row[documentNo-1].data[edited.label!]

                return edited

            }
        )
        // attachement = {...allPageAttachments[pageIndex]} as TextAttachment
        // attachement.x = attachement.x * ratio
        // attachement.y = attachement.y * ratio
        // attachement.size = attachement.size! * ratio
        // attachement.
    }

    const pageCountUp = ()=>{
        if(documentNo<row.length){
            setDocumentNo(documentNo+1)
        }
    }

    const pageCountDown = ()=>{
        if(documentNo>1){
            setDocumentNo(documentNo-1)
        }
    }

    return ReactDOM.createPortal(
        <div className='z-[999] p-5 fixed inset-0 bg-gray-900/40 overflow-hidden flex items-center justify-center'>
            {
                isLoading
                    ?
                    <div className='flex items-center justify-center p-20 bg-white rounded-md border-2 border-gray-400'>
                        Loading...
                    </div>
                    :
                    <div className=' h-full grow bg-white border-2 rounded-md border-gray-400 relative flex flex-col overflow-hidden'>
                        <div className=' absolute right-0 top-0 cursor-pointer text-white mix-blend-difference' onClick={close} ><IconX /></div>
                        <div className=' h-full w-full grow flex overflow-hidden  justify-center items-center'>
                            {imagesrc !== undefined && show
                                ?
                                <div
                                    style={{
                                        backgroundImage: `url('${imagesrc}')`
                                    }}
                                    className=' h-full w-full grow bg-contain bg-no-repeat bg-center '
                                />
                                :
                                null}
                        </div>
                        <div className='  min-h-11 bg-white border-t-2'>
                            <div className=' w-min mx-auto flex gap-1'>
                                <IconButton icon={<IconChevronLeft />} onClick={pageCountDown} />
                                    <Input disabled className='w-12 !h-10 text-center' value={documentNo}/>
                                <IconButton icon={<IconChevronRight />} onClick={pageCountUp} />
                            </div>
                        </div>
                    </div>
            }
        </div>,
        document.body
    )
}
