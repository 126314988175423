import React from 'react';
import { headerStateType } from '../App';


export  function DraggableElement({text,id,state = 'unselected',onSelect}:
    {
        onSelect:React.MouseEventHandler<HTMLDivElement>,
        state:headerStateType,
        id:string|number,
        text:string
    }) {

    return (
        <div onClick={onSelect} className={`bg-accent-100 hover:bg-accent-200 px-2 py-1 w-full cursor-pointer rounded-md border text-center ${state==='selected'?'border border-gray-500':''}`}>{text}</div>
    )
}
