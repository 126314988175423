import React, { RefObject } from 'react'

export type EditorCommonProps = {
    editorRef: RefObject<HTMLDivElement>,
    editorContentRef: RefObject<HTMLDivElement>
}
type Props = {
    // editorRef?: RefObject<HTMLDivElement>,   //box of editable field
    // editorContentRef?: RefObject<HTMLDivElement>    //box of content in edit
    sample?: any,
    children?: any,
    className?:string
}
export const Editor = React.forwardRef(
    function EditorRef({
        sample,
        children
    }: Props, ref) {
        return (
            <div className='grow flex flex-col overflow-hidden border-2 border-gray-800 rounded-md' ref={ref as any}   >
                {children}
            </div>
        )
    }
)
export const EditorContent = React.forwardRef(
    function EditorRef({
        sample,
        children,
        className
    }: Props, ref) {
        return (
            <div className={`grow flex overflow-hidden ${className}`}   >
                <div className='w-min m-auto relative' ref={ref as any}>
                    {children}
                </div>
            </div>
        )
    }
)
