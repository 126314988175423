import React from 'react'

type Props = {
    min: number,
    max: number,
    className?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    title?: string,
    value: number
}

type InputProp = {
    type?:'number'|'text',
    className?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    title?: string,
    value: string|number,
    disabled?:boolean
}

export default function Slider({
    min,
    max,
    className,
    onChange = (e) => { },
    title,
    value
}: Props) {

    

    return (
        <div className=' flex flex-col item-start gap-1'>

            {title ? <div className=' font-bold'>{title}</div> : null}
            <input onChange={onChange} type="range" value={value} min={min} max={max} className={` h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 ${className}`} />

        </div>
    )
}
export function Input({
    className,
    onChange = (e) => { },
    title,
    value,
    type='text',
    disabled=false
}: InputProp) {

    

    return (
        <div className=' flex flex-col item-start gap-1'>

            {title ? <div className=' font-bold'>{title}</div> : null}
            <input disabled={disabled} onChange={onChange} type={type} value={value} className={` h-12 rounded-sm border border-gray-300 ${className}`} />

        </div>
    )
}
