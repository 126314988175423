import { animated, useSpring } from '@react-spring/web'
import { IconDownload, IconLetterCase, IconZoomScan } from '@tabler/icons-react'
import { useGesture } from '@use-gesture/react'
import html2canvas from 'html2canvas'
import JSZip from 'jszip'
import React, { useEffect, useRef, useState } from 'react'
import { DefaultValues, headerDataType, imageResolutionType, rowDataType } from '../../App'
import { Attachments } from '../../components/attachements/Attachments'
import { IconButton } from '../../components/button'
import { Editor, EditorCommonProps, EditorContent } from '../../components/editorMeterial'
import { SimpleMenu } from '../../components/menu'
import Slider from '../../components/inputs'
import PreviewPage from './previewPage'
import { downloadImageByPage } from '../pdfEditor/utils/downloads'




export type Props = EditorCommonProps & {
    selectedImage: string | null,
    headerData: headerDataType[],
    imageResolution: imageResolutionType | undefined,
    row: rowDataType[],
    pageIndex: number,
    allPageAttachments: Attachments[],
    setAllPageAttachments: (attachmentsAll: Attachments[]) => void,
    screenScaleRef: React.MutableRefObject<number>,
    isElementDragging: React.MutableRefObject<boolean>,
    setDefaultValue: React.Dispatch<React.SetStateAction<DefaultValues>>,
    defaultValues: DefaultValues,
    setSelectedAttachment: (value: Attachment) => void,
    selectedAttachement:TextAttachment|undefined

}


export default function ImageEditor({
    selectedImage,
    headerData,
    imageResolution,
    editorRef,
    editorContentRef,
    row,
    pageIndex,
    allPageAttachments,
    setAllPageAttachments,
    screenScaleRef,
    isElementDragging,
    setDefaultValue,
    defaultValues,
    setSelectedAttachment,
    selectedAttachement
}: Props) {

    const [dimensions, setDimensions] = useState<Dimensions>();
    const dragBoxRef = useRef<HTMLDivElement>(null)
    const [loading, setloading] = useState<Boolean>(false)
    const [showPreview, setShowPreview] = useState<Boolean>(false)
    const scaleRef = useRef<number>(1)




    const downloadImage = async () => {
        const zip = new JSZip();
        setloading(true)
        if (editorContentRef.current && imageResolution) {

            for (let j = 0; j < row.length; j++) {

                const blob = await downloadImageByPage(
                    row[j],
                    imageResolution,
                    selectedImage!,
                    allPageAttachments[0]
                )


                zip.file(`certificat-${j + 1}.png`, blob); // Adjust extension based on chosen format

            }


            // Generate ZIP content as a blob
            const zipBlob = await zip.generateAsync({ type: 'blob' });

            // Create a temporary download link and trigger download
            const downloadUrl = URL.createObjectURL(zipBlob);

            // Simulate click (optional, consider using a button element)
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'image_container_in_zip.zip'; // Adjust filename
            link.click();

            // Clean up the temporary download link
            URL.revokeObjectURL(downloadUrl);


        }
        setloading(false)
    }

    const addAttachment = (attachment: Attachment) => {
        if (pageIndex < 0) return;
        // if (allPageAttachments.length === 0) {
        //     console.log('this is ', attachment)
        //     setAllPageAttachments([[attachment]])
        //     return
        // }
        // console.log(allPageAttachments)
        const newAllPageAttachmentsAdd = allPageAttachments.map(
            (attachments, index) =>
                pageIndex === index
                    ? [...attachments, attachment]
                    : attachments
        );
        // console.log(newAllPageAttachmentsAdd)
        setAllPageAttachments(newAllPageAttachmentsAdd)
    }
    const removeAttachment = (attachmentIndex: number) => {
        const newAllPageAttachmentsRemove = allPageAttachments.map(
            (otherPageAttachments, index) =>
                pageIndex === index
                    ? allPageAttachments[pageIndex].filter(
                        (_, _attachmentIndex) =>
                            _attachmentIndex !== attachmentIndex
                    )
                    : otherPageAttachments
        );
        setAllPageAttachments(newAllPageAttachmentsRemove)
    }
    const updateAttachment = (attachmentIndex: number, attachment: Partial<Attachment>) => {
        if (pageIndex === -1) {
            return;
        }

        // console.log(attachment)

        const newAllPageAttachmentsUpdate = allPageAttachments.map(
            (otherPageAttachments, index) =>
                pageIndex === index
                    ? allPageAttachments[pageIndex].map((oldAttachment, _attachmentIndex) => {
                        if (_attachmentIndex === attachmentIndex) {
                            setSelectedAttachment({ ...oldAttachment, ...attachment, selected: true })
                            return ({ ...oldAttachment, ...attachment, selected: true })
                        } else {
                            return ({ ...oldAttachment, selected: false })
                        }
                    }
                    )
                    : allPageAttachments[index].map(
                        attachment => { attachment.selected = false; return attachment }
                    )
        );
        setAllPageAttachments(newAllPageAttachmentsUpdate)
    }

    const changeFontSize = (fontSize: number) => {


        const newAllPageAttachmentsUpdate = allPageAttachments.map(
            (otherPageAttachments, index) =>
                pageIndex === index
                    ? allPageAttachments[pageIndex].map((oldAttachment, _attachmentIndex) => {
                        if (oldAttachment.selected === true) {
                            setSelectedAttachment({ ...oldAttachment, size: fontSize })
                            return ({ ...oldAttachment, size: fontSize })
                        } else {
                            return ({ ...oldAttachment })
                        }
                    }
                    )
                    : allPageAttachments[index].map(
                        attachment => { attachment.selected = false; return attachment }
                    )
        );

        setAllPageAttachments(newAllPageAttachmentsUpdate)

        setDefaultValue({
            ...defaultValues,
            fontSize: fontSize
        })
    }

    useEffect(
        () => {
            if (imageResolution?.height) {
                setDimensions({
                    x: 0,
                    y: 0,
                    height: imageResolution.height,
                    width: imageResolution.width
                })
                // console.log(imageResolution.height)
            }
        },
        [imageResolution]
    )

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
        rotateZ: 0,
    }))

    useGesture(
        {
            // onHover: ({ active, event }) => console.log('hover', event, active),
            // onMove: ({ event }) => console.log('move', event),
            onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
                // console.log(x,y)
                if (pinching || isElementDragging.current) return cancel()
                api.start({ x, y })
            },
            onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
                scaleRef.current = s
                // console.log('second')
                if (first) {
                    const { width, height, x, y } = dragBoxRef.current!.getBoundingClientRect()
                    const tx = ox - (x + width / 2)
                    const ty = oy - (y + height / 2)
                    memo = [style.x.get(), style.y.get(), tx, ty]
                }

                const x = memo[0] - (ms - 1) * memo[2]
                const y = memo[1] - (ms - 1) * memo[3]
                screenScaleRef.current = s
                api.start({ scale: s, x, y })
                return memo
            },
        },
        {
            target: dragBoxRef,
            drag: { from: () => [style.x.get(), style.y.get()] },
            // pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
        }
    )

    if (loading) {
        return (
            <div className=' grow flex justify-center items-center bg-white'>
                Downloading....
            </div>
        )
    }

    // console.log(selectedImage,imageResolution)
    return (
        <Editor ref={editorRef} >
            {/* CONFIGURATION SECTION */}
            <div className=' flex w-full justify-end bg-primarry-500 px-2 py-2 gap-2 z-50'>

                <SimpleMenu
                    interaction={<IconButton varient='primary' icon={<IconLetterCase />} />}
                    content={
                        <div className=' max-w-[400px] p-4'>
                            <Slider
                                title='Font Size'
                                min={10}
                                max={100}
                                onChange={(e) => changeFontSize(Number(e.target.value))}
                                value={selectedAttachement===undefined?0:selectedAttachement.size as number}
                            />
                        </div>
                    }
                />
                <IconButton varient='accent' icon={<IconDownload />} text='Download' onClick={downloadImage} />
                <IconButton varient='accent' icon={<IconZoomScan />} text='Preview' onClick={() => setShowPreview(true)} />
            </div>
            {/* CANVAS */}
            <animated.div className={'touch-none'} ref={dragBoxRef} style={{ ...style, height: imageResolution?.height, width: imageResolution?.width }}>

                <EditorContent ref={editorContentRef}>
                    <div>
                        {selectedImage && imageResolution
                            ? (
                                <img style={{ height: imageResolution.height, width: imageResolution.width, minWidth: imageResolution.width, userSelect: 'none' }} draggable={false} src={selectedImage} alt="Selected" />
                            )
                            :
                            null}

                        {dimensions && (
                            <Attachments
                                pdfName={'image'}
                                removeAttachment={removeAttachment}
                                updateAttachment={updateAttachment}
                                pageDimensions={dimensions}
                                attachments={allPageAttachments[pageIndex]}
                                isElementDragging={isElementDragging}
                                scale={scaleRef}
                            />
                        )}
                    </div>
                </EditorContent>
                <PreviewPage
                    show={showPreview}
                    close={() => setShowPreview(false)}
                    selectedImage={selectedImage}
                    imageResolution={imageResolution}
                    allPageAttachments={allPageAttachments}
                    pageIndex={pageIndex}
                    isElementDragging={isElementDragging}
                    headerData={headerData}
                    row={row}
                />
            </animated.div>
        </Editor>
    )
}
