import React, { useEffect, useRef, useState } from 'react'
import { getAsset } from './utils/prepareAssets';
import { readAsArrayBuffer } from './utils/asyncReader';
import JSZip from 'jszip';
import { ggID } from './utils/helpers';
import { PdfPage } from './components/pdfPage';
import { Attachments } from '../../components/attachements/Attachments';
import { Editor, EditorCommonProps, EditorContent } from '../../components/editorMeterial';
import { DefaultValues, headerDataType, rowDataType } from '../../App';
import { IconButton } from '../../components/button';
import { IconDownload, IconLetterCase, IconZoomScan } from '@tabler/icons-react';
import { SimpleMenu } from '../../components/menu';
import Slider from '../../components/inputs';
import PreviewPage from './components/previewPage';

export interface Pdf {
    name: string;
    file: File;
    pages: Promise<any>[];
}

type AttachmentType = 'image' | 'text' | 'drawing';
export enum AttachmentTypes {
    IMAGE = 'image',
    DRAWING = 'drawing',
    TEXT = 'text',
}

interface AttachmentBase {
    id: () => number;
    width: number;
    height: number;
    x: number;
    y: number;
    type: AttachmentType;
}


interface ImageAttachment extends AttachmentBase {
    file: File;
    img: HTMLImageElement;
}

interface DrawingAttachment extends AttachmentBase {
    path?: string;
    scale?: number;
    stroke?: string;
    strokeWidth?: number;
}

// type Attachment = ImageAttachment | DrawingAttachment | TextAttachment;


// type Attachments = Attachment[];

type Props = EditorCommonProps & {
    pdf: Pdf | undefined,
    headerData: headerDataType[],
    allPageAttachments: Attachments[],
    setAllPageAttachments: (attachmentsAll: Attachments[]) => void,
    pageIndex: number,
    setPageIndex: (index: number) => void,
    row: rowDataType[],
    setSelectedAttachment: (value: Attachment) => void,
    setDefaultValue: React.Dispatch<React.SetStateAction<DefaultValues>>,
    defaultValues: DefaultValues,
    selectedAttachement: TextAttachment | undefined

}

export function PdfEditor({
    pdf,
    allPageAttachments,
    setAllPageAttachments,
    pageIndex,
    setPageIndex,
    editorContentRef,
    editorRef,
    row,
    setSelectedAttachment,
    defaultValues,
    setDefaultValue,
    selectedAttachement,
    headerData

}: Props) {
    const [name, setName] = useState('');
    // const [pageIndex, setPageIndex] = useState(-1);
    const [file, setFile] = useState<File>();
    // const [pages, setPages] = useState<any[]>([]);
    const [isMultiPage, setIsMultiPage] = useState(false);
    const [isFirstPage, setIsFirstPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [showPreview, setShowPreview] = useState<Boolean>(false)

    const [scale, setScale] = useState(1)
    // const [allPageAttachments, setAllPageAttachments] = useState<Attachments[]>([])
    const [dimensions, setDimensions] = useState<Dimensions>();
    const isElementDragging = useRef(false)
    const scaleRef = useRef<number>(1)


    const downloadPdf = async () => {
        const zip = new JSZip();

        if (!pdf) return;
        const PDFLib = await getAsset('PDFLib');
        const download = await getAsset('download');


        for (let i = 0; i < row.length; i++) {
            let pdfDoc: {
                getPages: () => any[];
                embedFont: (arg0: unknown) => any;
                embedJpg: (arg0: unknown) => any;
                embedPng: (arg0: unknown) => any;
                embedPdf: (arg0: any) => [any] | PromiseLike<[any]>;
                save: () => any;
            };

            try {
                pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdf.file));

            } catch (e) {
                console.log('Failed to load PDF.');
                throw e;
            }
            const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {

                // console.log(allPageAttachments)
                const pageObjects = allPageAttachments[pageIndex];
                // 'y' starts from bottom in PDFLib, use this to calculate y
                if (pageObjects) {
                    const pageHeight = page.getHeight();
                    const embedProcesses = pageObjects.map(async (object: Attachment) => {

                        if (object.type === 'text') {
                            const {
                                x,
                                y,
                                text,
                                lineHeight,
                                size,
                                fontFamily,
                                // width,
                                label
                            } = object as TextAttachment;
                            const displayText = `${row[i].data[label!]}`

                            const pdfFont = await pdfDoc.embedFont(fontFamily);
                            return () =>
                                page.drawText(displayText, {
                                    // maxWidth: width,
                                    font: pdfFont,
                                    size,
                                    lineHeight,
                                    x,
                                    y: pageHeight - size! - y,
                                });
                        }
                    });
                    const drawProcesses: any[] = await Promise.all(embedProcesses);
                    drawProcesses.forEach((p) => p());
                }
                // embed objects in order

            });
            await Promise.all(pagesProcesses);
            try {
                const pdfBytes = await pdfDoc.save();
                // const zip = new JSZip();
                // Add the PDF bytes to the ZIP file
                zip.file(`document-${i + 1}.pdf`, pdfBytes);
                // Generate the ZIP file and save it

            } catch (e) {
                console.log('Failed to save PDF.');
                throw e;
            }

        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        download(zipBlob, 'documents.zip');

    }


    const addAttachment = (attachment: Attachment) => {
        if (pageIndex < 0) return;
        // if (allPageAttachments.length === 0) {
        //     console.log('this is ', attachment)
        //     setAllPageAttachments([[attachment]])
        //     return
        // }
        // console.log(allPageAttachments)
        const newAllPageAttachmentsAdd = allPageAttachments.map(
            (attachments, index) =>
                pageIndex === index
                    ? [...attachments, attachment]
                    : attachments
        );
        // console.log(newAllPageAttachmentsAdd)
        setAllPageAttachments(newAllPageAttachmentsAdd)
    }
    const removeAttachment = (attachmentIndex: number) => {
        const newAllPageAttachmentsRemove = allPageAttachments.map(
            (otherPageAttachments, index) =>
                pageIndex === index
                    ? allPageAttachments[pageIndex].filter(
                        (_, _attachmentIndex) =>
                            _attachmentIndex !== attachmentIndex
                    )
                    : otherPageAttachments
        );
        setAllPageAttachments(newAllPageAttachmentsRemove)
    }
    const updateAttachment = (attachmentIndex: number, attachment: Partial<Attachment>) => {
        if (pageIndex === -1) {
            return;
        }

        const newAllPageAttachmentsUpdate = allPageAttachments.map(
            (otherPageAttachments, index) =>
                pageIndex === index
                    ? allPageAttachments[pageIndex].map((oldAttachment, _attachmentIndex) => {
                        if (_attachmentIndex === attachmentIndex) {
                            setSelectedAttachment({ ...oldAttachment, ...attachment, selected: true })
                            return ({ ...oldAttachment, ...attachment, selected: true })
                        } else {
                            return ({ ...oldAttachment, selected: false })
                        }
                    }
                    )
                    : allPageAttachments[index].map(
                        attachment => { attachment.selected = false; return attachment }
                    )
        );
        setAllPageAttachments(newAllPageAttachmentsUpdate)
    }

    const addText = () => {
        const newTextAttachment: TextAttachment = {
            id: ggID(),
            type: AttachmentTypes.TEXT,
            x: 0,
            y: 0,
            width: 120,
            height: 25,
            size: 16,
            lineHeight: 1.4,
            fontFamily: 'Times-Roman',
            text: 'Enter Text Here',
            selected: true
        };
        addAttachment(newTextAttachment);
    };

    const changeFontSize = (fontSize: number) => {


        const newAllPageAttachmentsUpdate = allPageAttachments.map(
            (otherPageAttachments, index) =>
                pageIndex === index
                    ? allPageAttachments[pageIndex].map((oldAttachment, _attachmentIndex) => {
                        if (oldAttachment.selected === true) {
                            setSelectedAttachment({ ...oldAttachment, size: fontSize })
                            return ({ ...oldAttachment, size: fontSize })
                        } else {
                            return ({ ...oldAttachment })
                        }
                    }
                    )
                    : allPageAttachments[index].map(
                        attachment => { attachment.selected = false; return attachment }
                    )
        );

        setAllPageAttachments(newAllPageAttachmentsUpdate)

        setDefaultValue({
            ...defaultValues,
            fontSize: fontSize
        })
    }

    useEffect(
        () => {
            if (pdf) {
                const multi = pdf.pages.length > 1;
                setName(pdf.name);
                setFile(pdf.file);
                // setPages(pdf.pages);
                setPageIndex(0);
                setIsMultiPage(multi);
                setIsFirstPage(true);
                setIsLastPage(pdf.pages.length === 1);
            }
        }, [pdf]
    )

    return (
        <Editor ref={editorRef} >
            {/* CONFIGURATION SECTION */}
            <div className=' flex w-full justify-end bg-primarry-500 px-2 py-2 gap-2 z-50'>
                <SimpleMenu
                    interaction={<IconButton varient='primary' icon={<IconLetterCase />} />}
                    content={
                        <div className=' max-w-[400px] p-4'>
                            <Slider
                                title='Font Size'
                                min={10}
                                max={100}
                                onChange={(e) => changeFontSize(Number(e.target.value))}
                                value={selectedAttachement === undefined ? 0 : selectedAttachement.size as number}
                            />
                        </div>
                    }
                />
                <IconButton varient='accent' icon={<IconZoomScan />} text='Preview' onClick={() => setShowPreview(true)} />

                <IconButton icon={<IconDownload />} text='Download' onClick={downloadPdf} />
            </div>
            {/* CANVAS */}
            <EditorContent ref={editorContentRef} className='!overflow-auto'>
                <div>
                    {
                        pdf !== undefined && pdf.pages?.length && pdf.pages.length > 0 ?
                            <>
                                <PdfPage
                                    page={pdf.pages[pageIndex]}
                                    scale={scale}
                                    setDimensions={setDimensions}
                                />

                            </>
                            : null
                    }
                    {dimensions && (
                        <Attachments
                            pdfName={name}
                            removeAttachment={removeAttachment}
                            updateAttachment={updateAttachment}
                            pageDimensions={dimensions}
                            attachments={allPageAttachments[pageIndex]}
                            isElementDragging={isElementDragging}
                            scale={scaleRef}
                        />
                    )}
                </div>
            </EditorContent>
            <PreviewPage
                show={showPreview}
                close={() => setShowPreview(false)}
                selectedPdf={pdf}
                allPageAttachments={allPageAttachments}
                pageIndex={pageIndex}
                isElementDragging={isElementDragging}
                headerData={headerData}
                row={row}
            />
        </Editor>

    )
}
