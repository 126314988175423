import React from 'react';
import { AttachmentTypes } from '../../pages/pdfEditor/entities';
import { Text } from '../../containers/Text';

interface Props {
  attachments: Attachment[];
  pdfName: string;
  pageDimensions: Dimensions;
  removeAttachment: (index: number) => void;
  updateAttachment: (index: number, attachment: Partial<Attachment>) => void;
  isElementDragging:React.MutableRefObject<boolean>,
  isStyled?:boolean,
  scale:React.RefObject<number>

}

export const Attachments: React.FC<Props> = ({
  attachments,
  pdfName,
  pageDimensions,
  removeAttachment,
  updateAttachment,
  isElementDragging,
  isStyled = true,
  scale

}) => {
  const handleAttachmentUpdate = (index: number) => (
    attachment: Partial<Attachment>
  ) => updateAttachment(index, attachment);

  // console.log(attachments)
  return attachments ? (
    <>
      {attachments.length
        ? attachments.map((attachment, index) => {
          // const att = attachment as TextAttachment
          const key = `${pdfName}-${attachment.id()}`;
          if (attachment.type === AttachmentTypes.TEXT) {
            return (
              <Text
                key={key}
                pageWidth={pageDimensions.width}
                pageHeight={pageDimensions.height}
                updateTextAttachment={handleAttachmentUpdate(index)}
                {...attachment as TextAttachment}
                isElementDragging={isElementDragging}
                isStyled={isStyled}
                scale={scale}
              />
            );
          }
          return null;
        })
        : null}
    </>
  ) : null;
};
