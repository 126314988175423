import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { headerDataType, rowDataType } from '../../../App'
import { IconButton } from '../../../components/button'
import { Input } from '../../../components/inputs'
import { Pdf } from '../pdfEditor'
import { readAsArrayBuffer } from '../utils/asyncReader'
import { getAsset } from '../utils/prepareAssets'


type Props = {
    show: Boolean,
    close: () => void,
    selectedPdf: Pdf | undefined,
    allPageAttachments: Attachments[],
    pageIndex: number,
    isElementDragging: React.MutableRefObject<boolean>,
    headerData: headerDataType[],
    row: rowDataType[],
}

export default function PreviewPage({
    show = false,
    close,
    selectedPdf,
    allPageAttachments,
    pageIndex,
    isElementDragging,
    headerData,
    row
}: Props) {

    const [isLoading, setIsLoading] = useState<boolean>()
    const [ratio, setRatio] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0); const [documentNo, setDocumentNo] = useState<number>(1)
    const [editedPdfData, setEditedPdfData] = useState<Pdf>()
    const [pageIndex2, setPageIndex2] = useState(-1);

    function calculateY(x) {
        // Linear equation: y = mx + b
        // Here, we want y to decrease as x increases (negative slope)
        // We can adjust the slope (m) and y-intercept (b) to achieve different relationships

        const slope = -0.005; // Adjust slope for desired rate of decrease
        const yIntercept = 0.9; // Adjust y-intercept for starting y value

        return slope * x + yIntercept;
    }
    const renderPdfPage = async (page: Promise<any>, pageNo: number) => {

        if (editedPdfData) {
            const _page = await page
            if (_page) {

                const context = canvasRef.current?.getContext('2d');
                const viewport = _page.getViewport({ scale: 1 });
                setWidth(viewport.width);
                setHeight(viewport.height);

                // setDimensions(newDimensions as Dimensions)

                if (context) {
                    await _page.render({
                        canvasContext: canvasRef.current?.getContext('2d'),
                        viewport,
                    }).promise;
                }
                setPageIndex2(pageNo)

            }
        }

    }

    useEffect(
        () => {
            const context = canvasRef.current?.getContext('2d');
            let url: string

            async function handleInit() {

                setIsLoading(true)
                const PDFLib = await getAsset('PDFLib');
                const pdfJsLib = await getAsset('pdfjsLib')
                if (canvasRef.current && context) {

                    let pdfDoc: {
                        getPages: () => any[];
                        embedFont: (arg0: unknown) => any;
                        embedJpg: (arg0: unknown) => any;
                        embedPng: (arg0: unknown) => any;
                        embedPdf: (arg0: any) => [any] | PromiseLike<[any]>;
                        save: () => any;
                        saveAsBase64: (params: any) => any
                    };
                    if (selectedPdf === undefined) return;

                    try {
                        pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(selectedPdf.file));

                    } catch (e) {
                        console.log('Failed to load PDF.');
                        throw e;
                    }

                    const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {

                        // console.log(allPageAttachments)
                        const pageObjects = allPageAttachments[pageIndex];
                        // 'y' starts from bottom in PDFLib, use this to calculate y
                        if (pageObjects) {
                            const pageHeight = page.getHeight();
                            const embedProcesses = pageObjects.map(async (object: Attachment) => {

                                if (object.type === 'text') {
                                    const {
                                        x,
                                        y,
                                        text,
                                        lineHeight,
                                        size,
                                        fontFamily,
                                        // width,
                                        label
                                    } = object as TextAttachment;
                                    const displayText = `${row[documentNo - 1].data[label!]}`

                                    const pdfFont = await pdfDoc.embedFont(fontFamily);
                                    return () =>
                                        page.drawText(displayText, {
                                            // maxWidth: width,
                                            font: pdfFont,
                                            size,
                                            lineHeight,
                                            x,
                                            y: pageHeight -size!- y,
                                        });
                                }
                            });
                            const drawProcesses: any[] = await Promise.all(embedProcesses);
                            drawProcesses.forEach((p) => p());
                        }
                        // embed objects in order

                    });
                    await Promise.all(pagesProcesses);
                    try {
                        const pdfBytes = await pdfDoc.save();
                        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                        url = URL.createObjectURL(blob);

                        const pdfDocurl = await pdfJsLib.getDocument(url).promise;
                        const result = {
                            file: selectedPdf.file,
                            name: selectedPdf.name,
                            pages: Array(pdfDocurl.numPages)
                                .fill(0)
                                .map((_, index) => pdfDocurl.getPage(index + 1)),
                        } as Pdf;
                        setEditedPdfData(result)
                        renderPdfPage(result.pages[pageIndex2 < 0 ? 0 : pageIndex2], pageIndex2 < 0 ? 0 : pageIndex2)
                        console.log(result)
                        // const zip = new JSZip();
                        // Add the PDF bytes to the ZIP file
                        // Generate the ZIP file and save it

                    } catch (e) {
                        console.log('Failed to save PDF.');
                        throw e;
                    }

                    setIsLoading(false)

                }


            }
            if (show && selectedPdf) {
                handleInit()
            }

            return () => {
                if (url) {
                    URL.revokeObjectURL(url)
                }
            }



        }, [show, documentNo]
    )

    if (show === false) {
        return null
    }

    let attachements: TextAttachment[] = []

    if (show) {

        attachements = allPageAttachments[pageIndex].map(
            (attachement) => {
                const edited: TextAttachment = { ...attachement }

                edited.x = edited.x * ratio
                edited.y = edited.y * ratio
                edited.size = edited.size! * ratio
                edited.text = row[documentNo - 1].data[edited.label!]

                return edited

            }
        )
        // attachement = {...allPageAttachments[pageIndex]} as TextAttachment
        // attachement.x = attachement.x * ratio
        // attachement.y = attachement.y * ratio
        // attachement.size = attachement.size! * ratio
        // attachement.
    }

    const pageCountUp = () => {
        if (documentNo < row.length) {
            setDocumentNo(documentNo + 1)
        }
    }

    const pageCountDown = () => {
        if (documentNo > 1) {
            setDocumentNo(documentNo - 1)
        }
    }

    return ReactDOM.createPortal(
        <div className='z-[999] p-5 fixed inset-0 bg-gray-900/40 overflow-hidden flex items-center justify-center'>
            {

                <div className=' h-full grow bg-white border-2 rounded-md border-gray-400 relative flex flex-col overflow-hidden'>
                    <div className=' absolute right-0 top-0 cursor-pointer text-white mix-blend-difference' onClick={close} ><IconX /></div>
                    <div className='  grow flex overflow-hidden  justify-center items-center'>
                        <div className=' grow h-full overflow-auto'>
                            <canvas className='border mx-auto' ref={canvasRef} height={height} width={width}></canvas>

                        </div>
                    </div>
                    <div className=' flex  min-h-11 bg-white border-t-2 relative'>
                        <div className=' w-min mx-auto flex gap-1'>
                            <IconButton icon={<IconChevronLeft />} onClick={pageCountDown} />
                            <Input disabled className='w-12 !h-10 text-center' value={documentNo} />
                            <IconButton icon={<IconChevronRight />} onClick={pageCountUp} />
                        </div>
                        {
                            (editedPdfData?.pages?.length && editedPdfData?.pages.length)
                                ?
                                <div className=' flex items-center gap-1 absolute right-3 top-1/2 -translate-y-1/2 '>
                                    <div>Page</div>
                                    <div className=' m-auto flex border rounded-md w-min'>
                                        <div className='p-2' onClick={
                                            () => {
                                                if (pageIndex2 > 0) {
                                                    const pageNo = pageIndex2 - 1
                                                    renderPdfPage(editedPdfData.pages[pageNo], pageNo)
                                                }
                                            }
                                        } >{'<'}</div>
                                        <div className=' border-x p-2 whitespace-nowrap'>{pageIndex2 + 1} of {editedPdfData.pages.length}</div>
                                        <div className=' p-2' onClick={
                                            () => {
                                                if (editedPdfData.pages.length > pageIndex2 + 1) {
                                                    const pageNo = pageIndex2 + 1
                                                    renderPdfPage(editedPdfData.pages[pageNo], pageNo)
                                                }
                                            }
                                        } >{'>'}</div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            }
        </div>,
        document.body
    )
}
